<template>
  <v-card elevation="0" :max-width="card ? 1010 : 600" rounded="xl" class="mx-auto pa-sm-8 pa-4 login width100">
    <div v-if="card">
      <v-img height="105" contain class="mx-auto mb-12" src="@/assets/icon/logo.svg" alt="logo"></v-img>
      <div class="f30 arabic-black text-center mb-2 black--text">{{ $t('passwordRecovery.title') }}</div>
      <div style="max-width: 420px" class="gray--text text-center mb-4 mx-auto">{{ $t('passwordRecovery.description') }}</div>
      <div style="max-width: 380px" class="mx-auto">
        <v-form @submit.prevent="action" ref="form" lazy-validation>
          <div class="black--text">{{ $t('input.password') }}</div>
          <v-text-field
            v-model.trim="data.password"
            :placeholder="$t('input.password')"
            :error-messages="passwordErrors"
            :type="show ? 'text' : 'password'"
            outlined
            required
            color="secondary"
          >
            <template v-slot:append>
              <img class="link" width="24" height="24" @click="show = !show" :src="getIcon(show ? 'passShow' : 'passHide')" />
            </template>
          </v-text-field>
          <div class="black--text">{{ $t('input.confirmNewPassword') }}</div>
          <v-text-field
            v-model.trim="data.confirmPassword"
            :placeholder="$t('input.confirmNewPassword')"
            :error-messages="passwordConfirmErrors"
            :type="showCom ? 'text' : 'password'"
            outlined
            required
            color="secondary"
          >
            <template v-slot:append>
              <img class="link" width="24" height="24" @click="showCom = !showCom" :src="getIcon(showCom ? 'passShow' : 'passHide')" />
            </template>
          </v-text-field>
          <v-btn height="64" depressed type="submit" block large class="primary radius10 mb-10 mt-7">
            <span class="text-body-1 font-weight-bold">{{ $t('btn.continue') }}</span>
          </v-btn>
        </v-form>
      </div>
    </div>
    <div v-else class="text-center">
      <lottie :img="img" :height="120" :width="120" />
      <div class="text-h5 arabic-black my-2 black--text">{{ $t('passwordRecovery.expiredTitle') }}</div>
      <div style="max-width: 420px" class="f15 gray--text mb-12 mx-auto">{{ $t('passwordRecovery.expiredDescription') }}</div>
      <router-link class="d-block mb-9 secondary--text text-body-2 font-weight-bold btn-underline mx-auto" to="/sign-in">
        {{ $t('btn.signIn') }}
      </router-link>
    </div>
  </v-card>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators';
import Lottie from '@/components/lottie.vue';
import img from '@/assets/icon/expired.json';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    lottie: Lottie,
  },
  validations: {
    data: {
      password: {
        required,
      },
      confirmPassword: {
        sameAsPassword: sameAs('password'),
      },
    },
  },
  data() {
    return {
      img: img,
      data: {},
      card: true,
      show: false,
      showCom: false,
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'ok') {
        this.$notify({
          title: '',
          message: this.$t('alert.confirmPassword'),
          type: 'success',
          icon: 'mdi-check',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    getIcon(code) {
      return require(`@/assets/icon/${code}.svg`);
    },
    async action() {
      this.error = [];
      const data = new Object();
      data.password = this.data.password;
      data.token = this.$route.query.token;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.$store
          .dispatch('passReset', data)
          .then(() => {
            this.notifi('ok');
            this.$router.push('/sign-in');
          })
          .catch((e) => {
            this.error = e.response.data.error;
            if (this.error.find((i) => i == 'token__invalid') || this.error.find((i) => i == 'token__expired')) {
              this.card = false;
            }
          });
      }
    },
  },
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.data.password.$dirty) {
        return errors;
      }
      !this.$v.data.password.required && errors.push(this.$t('inputError.passwordRequired'));
      this.error.find((item) => item == 'password__required') && errors.push(this.$t('inputError.passwordRequired'));
      this.error.find((item) => item == 'password__invalid') && errors.push(this.$t('inputError.passwordInvalid'));
      this.error.find((item) => item == 'bad_credentials') && errors.push(this.$t('inputError.badCredLogin'));
      return errors;
    },
    passwordConfirmErrors() {
      const errors = [];
      if (!this.$v.data.confirmPassword.$dirty) {
        return errors;
      }
      !this.$v.data.confirmPassword.sameAsPassword && errors.push(this.$t('inputError.passwordConfirm'));
      return errors;
    },
  },
};
</script>
